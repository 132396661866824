import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'

import { FollowButton } from '@common/FollowButton'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useIsDesktop } from '@hooks/useIsDesktop'
import UrlHelper from '@lib/UrlHelper'
import urlHelper from '@lib/UrlHelper'
import { useToggleUserFollow } from '@pages/user/hooks/useToggleUserFollow'
import nodeApi from '@redux/api/nodeApi'
import userApi from '@redux/api/userApi'
import { useAppSelector } from '@redux/store/store'

const UserSidePanel = ({ userId }: { userId: number | undefined }) => {
  const { currentUserID, isLoggedIn } = useCurrentUserContext()
  const identifier = useAppSelector(state => state.feed.identifier)
  const router = useRouter()
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const [isOwnProfile, setIsOwnProfile] = useState(false)
  const trackClick = useTrackClick()
  const handleFollow = useToggleUserFollow(user)
  const isDesktop = useIsDesktop()

  const tabsRef = useRef<HTMLDivElement>(null)
  const [showLeftGradient, setShowLeftGradient] = useState(false)
  const [showRightGradient, setShowRightGradient] = useState(true)

  const leftGradientId = `scrollFadeLeft-${userId}`
  const rightGradientId = `scrollFadeRight-${userId}`
  const leftMaskId = `maskLeft-${userId}`
  const rightMaskId = `maskRight-${userId}`

  const { data: blockedData } = nodeApi.useGetBlockedNodesQuery({ page: 1 }, { skip: !isOwnProfile })
  const blockedCount = blockedData?.items?.length || 0

  const checkScroll = useCallback(() => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current
      const SCROLL_BUFFER = 5
      setShowLeftGradient(scrollLeft > SCROLL_BUFFER)
      setShowRightGradient(scrollLeft + clientWidth < scrollWidth - SCROLL_BUFFER)
    }
  }, [])

  useEffect(() => {
    const TIMEOUT_MS = 50
    const timer = setTimeout(() => {
      checkScroll()
    }, TIMEOUT_MS)
    return () => clearTimeout(timer)
  }, [checkScroll, user])

  useEffect(() => {
    const tabsElement = tabsRef.current
    if (tabsElement) {
      tabsElement.addEventListener('scroll', checkScroll)
      return () => tabsElement.removeEventListener('scroll', checkScroll)
    }
  }, [checkScroll])

  useEffect(() => {
    window.addEventListener('resize', checkScroll)
    return () => window.removeEventListener('resize', checkScroll)
  }, [checkScroll])

  useEffect(() => {
    setIsOwnProfile(currentUserID === user?.user_id)
  }, [currentUserID, user?.user_id])

  if (!user) return <></>

  const SettingsButton = () => (
    <Link
      href={'/settings'}
      onClick={() => trackClick('userSettings')}
      className="btn btn-primary btn-dot bg-white font-semibold text-black"
    >
      <span className="size-4 rounded-full bg-orange-400"></span>
      <span>Settings</span>
    </Link>
  )

  return (
    <>
      <div className="flex flex-1 flex-col items-start lg:h-full">
        {isDesktop ? (
          <>
            <div className="flex space-x-10 text-primary sm:flex-col sm:space-x-0 sm:space-y-4">
              <Image
                className="block aspect-square size-32 shrink-0 rounded-full object-contain md:size-40"
                src={user.photo_url ?? ''}
                alt={user.display_name}
                width={400}
                height={400}
              />
              <div className="flex flex-col self-center sm:flex-col sm:items-start sm:space-y-2 sm:self-auto">
                <p className="font-sans text-3xl font-bold text-primary lg:text-4xl">{user.display_name}</p>
                <p className="text-primary opacity-50 lg:text-2xl">{`@${user.username}`}</p>
                {user?.bio?.length ? <p className="mt-4 text-primary opacity-75 sm:mt-0">{user.bio}</p> : <></>}
              </div>
            </div>

            <div className="my-8 flex w-full items-center justify-between gap-4">
              {isOwnProfile ? (
                <SettingsButton />
              ) : identifier.slug === 'user' ? (
                <>
                  <FollowButton className="btn-dot" isFollowing={user.meta.isLiked ?? false} onFollow={handleFollow} />
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <div className="pb-2">
            <div className="mb-5 flex">
              <Image
                className="mr-5 block aspect-square size-24 shrink-0 rounded-full object-contain"
                src={user.photo_url ?? ''}
                alt={user.display_name}
                width={200}
                height={200}
              />
              <div className="flex flex-col">
                <h1 className="text-[28px] font-bold leading-[1.2]">{user.display_name}</h1>
                <p className="text-base text-gray-400">{`@${user.username}`}</p>
                {user?.bio?.length ? <p className="mt-1 text-base text-gray-500">{user.bio}</p> : null}
              </div>
            </div>
            {!isOwnProfile && identifier.slug === 'user' && (
              <div className="mb-6 flex justify-center gap-2">
                <FollowButton className="btn-dot" isFollowing={user.meta.isLiked ?? false} onFollow={handleFollow} />
              </div>
            )}
          </div>
        )}

        <div className="relative w-full">
          <svg width="0" height="0" style={{ position: 'absolute' }}>
            <defs>
              <linearGradient id={leftGradientId} x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="white" stopOpacity="1" />
                <stop offset="70%" stopColor="white" stopOpacity="0.5" />
                <stop offset="100%" stopColor="white" stopOpacity="0" />
              </linearGradient>
              <linearGradient id={rightGradientId} x1="1" y1="0" x2="0" y2="0">
                <stop offset="0%" stopColor="white" stopOpacity="1" />
                <stop offset="100%" stopColor="white" stopOpacity="0" />
              </linearGradient>
              <mask id={leftMaskId} maskContentUnits="objectBoundingBox">
                <rect width="1" height="1" fill={`url(#${leftGradientId})`} />
              </mask>
              <mask id={rightMaskId} maskContentUnits="objectBoundingBox">
                <rect width="1" height="1" fill={`url(#${rightGradientId})`} />
              </mask>
            </defs>
          </svg>

          <div className="w-full overflow-hidden" style={{ position: 'relative' }}>
            <div className="w-full overflow-hidden bg-primary">
              <div
                ref={tabsRef}
                className="flex w-full self-stretch overflow-x-auto px-px font-medium scrollbar-hide sm:flex-col"
                onLoad={checkScroll}
              >
                <StatLink
                  href={UrlHelper.userPath(user.username)}
                  count={user?.meta.likesCount}
                  label="Likes"
                  isActive={router.asPath === urlHelper.userPath(user.username)}
                />
                {isLoggedIn && (
                  <StatLink
                    href={urlHelper.userSubPath(user.username, 'posts')}
                    count={user.meta.curatedCount}
                    label="Posts"
                    isActive={router.asPath === urlHelper.userSubPath(user.username, 'posts')}
                  />
                )}
                <StatLink
                  href={urlHelper.userSubPath(user.username, 'following')}
                  count={user.meta.followingCount}
                  label="Following"
                  isActive={router.asPath === urlHelper.userSubPath(user.username, 'following')}
                />
                {isOwnProfile && (
                  <>
                    <StatLink
                      href={urlHelper.userSubPath(user.username, 'followers')}
                      count={user.meta.followersCount}
                      label="Followers"
                      isActive={router.asPath === urlHelper.userSubPath(user.username, 'followers')}
                    />
                    <StatLink
                      href={urlHelper.userSubPath(user.username, 'saved')}
                      count={user.meta.savedCount}
                      label="Saved"
                      isActive={router.asPath === urlHelper.userSubPath(user.username, 'saved')}
                    />
                    <StatLink
                      href={urlHelper.userSubPath(user.username, 'blocked')}
                      count={blockedCount}
                      label="Blocked"
                      isActive={router.asPath === urlHelper.userSubPath(user.username, 'blocked')}
                    />
                  </>
                )}
              </div>
            </div>

            <div
              className="pointer-events-none absolute right-0 top-0 h-full w-32 bg-primary sm:hidden"
              style={{
                opacity: showRightGradient ? 1 : 0,
                transition: 'opacity 0.2s ease',
                mask: `url(#${rightMaskId})`,
                WebkitMask: `url(#${rightMaskId})`,
              }}
            />

            <div
              className="pointer-events-none absolute left-0 top-0 h-full w-40 bg-primary sm:hidden"
              style={{
                opacity: showLeftGradient ? 1 : 0,
                transition: 'opacity 0.2s ease',
                mask: `url(#${leftMaskId})`,
                WebkitMask: `url(#${leftMaskId})`,

                left: '-5px',
              }}
            />

            <div className="pointer-events-none absolute left-0 top-0 h-full w-1 bg-primary sm:hidden" />
          </div>
        </div>
      </div>
    </>
  )
}

const StatLink = ({
  href,
  count,
  label,
  isActive,
}: {
  href: string
  count?: number
  label: string
  isActive: boolean
}) => {
  const displayCount =
    count !== undefined ? (
      <span className="font-mono text-sm text-primary sm:order-2">{count.toLocaleString()}</span>
    ) : (
      <span className="font-mono text-sm text-primary sm:order-2"> • </span>
    )

  return (
    <Link
      as={href}
      href={`${href}/?skipUserSSR=true`}
      className={`tab relative flex min-w-[70px] flex-1 flex-col items-center justify-between p-2 sm:min-w-0 sm:flex-row sm:px-0 sm:leading-[3] ${isActive ? 'tab-active' : 'tab-inactive'} `}
    >
      {displayCount}
      <span className="text-xs font-semibold text-primary sm:order-1 sm:text-base">{label}</span>
    </Link>
  )
}

export default UserSidePanel
